import React from 'react'
import {teammember, teammember_image, teammember_info, teammember_title} from './TeamMember.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const TeamMember = ( props ) => {
    const altText = "Headshot of " + props.name
    const image = props.image ? getImage(props.image) : null;

    return (
        <div className={teammember}>
            
            {image && <GatsbyImage image={image} alt={altText} className={teammember_image} />}

            <div className={teammember_info}>
                <span>{props.name}</span>
                <span className={teammember_title}>{props.title}</span>
            </div>
        </div>
    )
}

export default TeamMember
