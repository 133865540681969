import React from "react"
import TeamMember from "../TeamMember"
import { teamgrid } from "./TeamGrid.module.css"

const TeamGrid = ({ employees }) => {
  return (
    <div className={teamgrid}>
      {employees.map(employee => {
        const imageData = employee.headshot
          ? { image: employee.headshot.gatsbyImageData }
          : null

        return (
          <TeamMember
            key={employee.fullName}
            name={employee.fullName}
            title={employee.title}
            {...imageData}
          />
        )
      })}
    </div>
  )
}

export default TeamGrid
