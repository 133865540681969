import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TeamGrid from "../components/TeamGrid"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const About = ({ data, location }) => {
  const content = data.contentfulAboutPage
  const employees = data.contentfulTeam.team

  const Bold = ({ children }) => <span style={{fontWeight: "600"}}>{children}</span>
  const Italic = ({ children }) => <em>{children}</em>
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (post, children) => <Text>{children}</Text>,
    },
  }

  return (
    <Layout location={location}>
      <Seo title="About" />
      <div className="section-wrapper page-main">
        <div className="content-column">
          <h1 className="page-main-title">About</h1>
          <div className="text-section">
            <h2 id="missionstatement">Mission Statement</h2>
            <p>{content.missionStatement.missionStatement}</p>
          </div>
          <div className="text-section">
            <h2 id="aboutmedea">About Medea</h2>
            <div>{renderRichText(content.aboutMedea, options)}</div>
          </div>
          <div>
            <h2 id="team">Team</h2>
            <TeamGrid employees={employees}/>
          </div>
          
        </div>
        <div className="index-column">
          <AnchorLink to="/about#missionstatement" className="index-item">Mission Statement</AnchorLink>
          <AnchorLink to="/about#aboutmedea" className="index-item">About Medea</AnchorLink>
          <AnchorLink to="/about#team" className="index-item">Team</AnchorLink>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutContent {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAboutPage {
      missionStatement {
        missionStatement
      }
      aboutMedea {
        raw
      }
    }
    contentfulTeam {
      team {
        fullName
        title
        headshot {
          ... on ContentfulAsset {
            gatsbyImageData(
              width: 240
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  }
`